<template>
  <div class="formula-canvas">
    <div ref="canvas" class="canvas"></div>
    <div class="symbols">
      <el-button
        v-for="symbol in symbols"
        :key="symbol"
        class="symbol"
        @click="addSymbol(symbol)"
      >
        {{ symbol }}
      </el-button>
    </div>
    <el-button-group class="canvas-controls">
      <el-button type="warning" icon="el-icon-back" @click="undo" :disabled="!canUndo">Undo</el-button>
      <el-button type="danger" icon="el-icon-delete" @click="clearCanvas">Clear</el-button>
      <el-button type="success" icon="el-icon-camera" @click="saveImage">Save Formula</el-button>
    </el-button-group>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import interact from 'interactjs';

export default {
  name: 'FormulaCanvas',
  data() {
    return {
      symbols: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '-', '*', '/', '=', '(', ')', 'x', 'y', 'z', '∫', '∑', '√', 'π', '∞'],
      history: [],
      currentStep: -1
    }
  },
  computed: {
    canUndo() {
      return this.currentStep > 0
    }
  },
  mounted() {
    this.initCanvas()
  },
  methods: {
    initCanvas() {
      // 初始化画布
    },
    addSymbol(symbol) {
      const element = document.createElement('span')
      element.textContent = symbol
      element.style.position = 'absolute'
      element.style.left = '50%'
      element.style.top = '50%'
      element.style.transform = 'translate(-50%, -50%)'
      this.$refs.canvas.appendChild(element)

      this.makeInteractive(element)
      this.saveState()
    },
    makeInteractive(element) {
      interact(element).draggable({
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        autoScroll: true,
        listeners: {
          move: (event) => {
            const target = event.target
            const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
            const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

            target.style.transform = `translate(${x}px, ${y}px)`
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
          },
          end: () => this.saveState()
        }
      })
    },
    clearCanvas() {
      this.$refs.canvas.innerHTML = ''
      this.saveState()
    },
    async saveImage() {
      const canvas = await html2canvas(this.$refs.canvas)
      const imageData = canvas.toDataURL('image/png')
      this.$emit('formula-image', imageData)
    },
    saveState() {
      this.currentStep++
      this.history = this.history.slice(0, this.currentStep)
      this.history.push(this.$refs.canvas.innerHTML)
    },
    undo() {
      if (this.canUndo) {
        this.currentStep--
        this.$refs.canvas.innerHTML = this.history[this.currentStep]
        this.$nextTick(() => {
          this.$refs.canvas.querySelectorAll('span').forEach(this.makeInteractive)
        })
      }
    }
  }
}
</script>
<style scoped>
.formula-canvas {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.canvas {
  width: 100%;
  height: 300px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  background-color: #f5f7fa;
  margin-bottom: 15px;
}

.symbols {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
}

.symbol {
  margin: 2px;
  font-size: 18px;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-controls {
  display: flex;
  justify-content: center;
}

.canvas span {
  cursor: move;
  user-select: none;
  font-size: 50px; /* 增大画布上的符号大小 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 50px; /* 增大最小宽度 */
  min-height: 50px; /* 增大最小高度 */
  background-color: white;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px; /* 添加一些内边距 */
}
</style>
