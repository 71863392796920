<template>
  <div>
    <div ref="plotContainer" class="plot-container"></div>
    <el-alert v-if="error" :title="error" type="error" show-icon></el-alert>
    <el-alert v-if="loading" title="Loading..." type="info" show-icon></el-alert>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min'

export default {
  name: 'ModelViewer',
  props: {
    pythonCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      error: ''
    }
  },
  mounted() {
    this.waitForPyodide();
  },
  methods: {
    waitForPyodide() {
      if (window.pyodide) {
        this.loading = false;
        this.runPythonCode(this.pythonCode);
      } else {
        setTimeout(this.waitForPyodide, 100);
      }
    },
async runPythonCode(code) {
  if (window.pyodide) {
    try {
      const cleanedCode = this.cleanPythonCode(code);
      // 只加载 numpy 包
      await window.pyodide.loadPackage('numpy');
      const result = await window.pyodide.runPythonAsync(`
import json
import numpy as np
${cleanedCode}

result = create_3d_plot()
json.dumps(result)
      `.trim());
      
      const data = JSON.parse(result);
      this.renderPlot(data);
      
      this.error = '';
    } catch (error) {
      console.error('Error running Python code:', error);
      this.error = `Error: ${error.message}`;
    }
  } else {
    console.error('Pyodide is not loaded');
    this.error = 'Error: Pyodide is not loaded';
  }
},
    renderPlot(data) {
      const layout = {
        scene: {
          xaxis: { title: 'X' },
          yaxis: { title: 'Y' },
          zaxis: { title: 'Z' }
        },
        margin: { l: 0, r: 0, b: 0, t: 0 }
      };

      let plotData;
      if (data.type === 'surface') {
        plotData = [{
          type: 'surface',
          x: data.x,
          y: data.y,
          z: data.z
        }];
      } else if (data.type === 'scatter3d') {
        plotData = [{
          type: 'scatter3d',
          mode: 'markers',
          x: data.x,
          y: data.y,
          z: data.z,
          marker: { size: 2, color: 'blue' }
        }];
      }

      Plotly.newPlot(this.$refs.plotContainer, plotData, layout);
    },
    cleanPythonCode(code) {
      return code.replace(/```python\n/g, '').replace(/```\n?/g, '').trim();
    }
  },
  watch: {
    pythonCode: {
      handler(newCode) {
        if (!this.loading) {
          this.runPythonCode(newCode);
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.plot-container {
  width: 100%;
  height: 400px;
}
</style>
