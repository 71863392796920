<template>
  <div class="home">
    <h1>AI-3D 模型生成器</h1>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
        <el-card class="upload-card">
          <div slot="header">
            <span>模型图片/公式(支持手写)图片上传</span>
          </div>
          <el-upload
            class="upload-demo"
            drag
            action="#"
            :auto-upload="false"
            :on-change="handleImageUpload"
            :show-file-list="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖拽文件到此处或 <em>点击上传</em></div>
          </el-upload>
          <img v-if="uploadedImage" :src="uploadedImage" class="preview-image" />
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
        <el-card class="input-card">
          <div slot="header">
            <span>输入</span>
          </div>
          <el-input
            type="textarea"
            :rows="4"
            v-model="userInput"
            placeholder="描述你想生成的3D模型..."
          ></el-input>
          <el-button type="primary" @click="generateModel" :loading="isLoading">
            <i class="el-icon-magic-stick"></i> 生成模型
          </el-button>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px;">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-card class="formula-card">
          <div slot="header">
            <span>公式画布</span>
          </div>
          <FormulaCanvas @formula-image="handleFormulaImage" />
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-card class="model-card">
          <div slot="header">
            <span>3D 模型</span>
          </div>
          <ModelViewer ref="modelViewer" :pythonCode="generatedCode" />
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="处理中"
      :visible.sync="isLoading"
      :close-on-click-modal="false"
      :show-close="false"
      :modal="false"
      custom-class="transparent-dialog"
    >
      <div class="dialog-content">
        <el-progress type="circle" :percentage="loadingProgress"></el-progress>
        <p>{{ loadingMessage }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormulaCanvas from '@/components/FormulaCanvas.vue'
import ModelViewer from '@/components/ModelViewer.vue'

export default {
  name:'HomePage',
  components: {
    FormulaCanvas,
    ModelViewer
  },
  data() {
    return {
      userInput: '',
      generatedCode: '',
      isLoading: false,
      loadingProgress: 0,
      loadingMessage: '',
      error: null,
      selectedModel: process.env.VUE_APP_MODELS.split(',')[0],
      selectedVisionModel: process.env.VUE_APP_VISION_MODELS.split(',')[0],
      uploadedImage: null,
      recognizedFormula: ''
    }
  },
  methods: {
    async generateModel() {
      this.isLoading = true
      this.loadingProgress = 0
      this.loadingMessage = '正在生成模型...'
      this.error = null
      try {
        // 在 generateModel 方法中
        let prompt = `Generate a Python function that creates data for a 3D plot based on the following description or formula. The function MUST be named 'create_3d_plot' and MUST return a dictionary containing the data. Do not include any Markdown formatting or code block indicators. The function should:
        
        1. Import necessary libraries (mainly numpy as np)
        2. Generate the appropriate data based on the given description or formula
        3. Return a dictionary with the following structure:
           {
             'x': x_data,
             'y': y_data,
             'z': z_data,
             'type': 'surface' or 'scatter3d' (depending on the data)
           }
        
        Here's the description or formula: ${this.userInput}
        
        If a formula was recognized from an image, use this as well: ${this.recognizedFormula}
        
        Please provide the complete Python code, including all necessary imports and the FULL function definition for 'create_3d_plot'.
        
        Example of a valid response:
        
        import numpy as np
        
        def create_3d_plot():
            # Generate data (example: a simple sine wave)
            x = np.linspace(-5, 5, 100)
            y = np.linspace(-5, 5, 100)
            X, Y = np.meshgrid(x, y)
            Z = np.sin(np.sqrt(X**2 + Y**2))
            
            return {
                'x': X.tolist(),
                'y': Y.tolist(),
                'z': Z.tolist(),
                'type': 'surface'
            }
        
        Ensure that your generated function follows this structure and includes all necessary components.`;

        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/chat/completions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          },
          body: JSON.stringify({
            model: this.selectedModel,
            messages: [{ role: 'user', content: prompt }],
            stream: true
          })
        })

        const reader = response.body.getReader()
        const decoder = new TextDecoder('utf-8')
        let result = ''
        const expectedTotalLength = 1000 // 预计的总代码长度，可以根据实际情况调整
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          const chunk = decoder.decode(value, { stream: true })
          const lines = chunk.split('\n').filter(line => line.trim() !== '')
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(6))
                if (data.choices[0].delta && data.choices[0].delta.content) {
                  result += data.choices[0].delta.content
                  this.generatedCode = result.trim()
                  this.loadingProgress = Math.min(99, Math.floor((result.length / expectedTotalLength) * 100))
                }
              } catch (error) {
                console.error('解析JSON时出错:', error)
              }
            }
          }
        }
        this.loadingProgress = 100
        this.loadingMessage = '模型生成成功！'
        this.saveCodeState()
      } catch (error) {
        this.error = '生成模型时出错: ' + error.message
        this.loadingMessage = '生成模型时发生错误。'
      } finally {
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
      }
    },
    updateModel(newCode) {
      this.generatedCode = newCode
      this.saveCodeState()
    },
    saveCodeState() {
      this.currentCodeIndex++
      this.codeHistory = this.codeHistory.slice(0, this.currentCodeIndex)
      this.codeHistory.push(this.generatedCode)
    },
    undoCodeChange() {
      if (this.canUndo) {
        this.currentCodeIndex--
        this.generatedCode = this.codeHistory[this.currentCodeIndex]
        this.$refs.modelViewer.runPythonCode(this.generatedCode)
      }
    },
    rerunCode() {
      this.$refs.modelViewer.runPythonCode(this.generatedCode)
    },
    handleFormulaImage(imageData) {
          this.uploadedImage = imageData
          this.processImage()
        },
    handleImageUpload(file) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.uploadedImage = e.target.result
            this.processImage()
          }
          reader.readAsDataURL(file.raw)
    },
    async processImage() {
      if (!this.uploadedImage) return

      this.isLoading = true
      this.loadingProgress = 0
      this.loadingMessage = '正在处理图片...'
      this.error = null

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/chat/completions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          },
          body: JSON.stringify({
            model: this.selectedVisionModel,
            messages: [
              {
                role: 'user',
                content: [
                  { type: 'text', text: "What's in this image? If it's a mathematical formula, please provide the LaTeX representation." },
                  { type: 'image_url', image_url: { url: this.uploadedImage } }
                ]
              }
            ]
          })
        })

        const data = await response.json()
        this.recognizedFormula = data.choices[0].message.content
        this.userInput = `Generate a 3D model based on this formula: ${this.recognizedFormula}`
        this.loadingProgress = 100
        this.loadingMessage = '图片处理成功！'
      } catch (error) {
        this.error = '处理图片时出错: ' + error.message
        this.loadingMessage = '处理图片时发生错误。'
      } finally {
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.el-card {
  margin-bottom: 20px;
}

.input-card .el-button {
  margin-top: 10px;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  margin-top: 10px;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-content p {
  margin-top: 20px;
}

.transparent-dialog {
  background: transparent !important;
  box-shadow: none !important;
}

.transparent-dialog .el-dialog__header,
.transparent-dialog .el-dialog__body {
  background: transparent !important;
}

.model-card, .formula-card {
  height: calc(100% - 20px);
}

@media (max-width: 768px) {
  .home {
    padding: 10px;
  }

  .el-card {
    margin-bottom: 10px;
  }
}
</style>